import React, { useRef } from 'react'
import './PortraitVideo.css'
import img from '../../../../images/Group 852.png'
import Loadable from "@loadable/component"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import user from '../../../../images/Ellipse 155.png'
import arrowLeft from '../../../../images/left.png'
import arrowRight from '../../../../images/right.png'
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from 'react-player/youtube'
import { useMediaQuery } from 'react-responsive'

const OwlCarousel = Loadable(() => import("react-owl-carousel"))

export default function PortraitVideo({ feedback }) {
  const owlRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: "575.98px" })
  const isMobileSmall = useMediaQuery({ minWidth: "576px", maxWidth: "767.98px" })
  const isTablet = useMediaQuery({ minWidth: "768px", maxWidth: "991.98px" })
  const isDesktop = useMediaQuery({ minWidth: "992px", maxWidth: "1199.98px" })
  const isDesktopLarge = useMediaQuery({ minWidth: "1200px", })

  console.log(isTablet, "tab")
  console.log(isDesktopLarge, "lg")

  const options = {
    responsiveClass: true,
    autoplay: false,
    smartSpeed: 1000,
    items: 3,
    dots: false,
    center: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,

      }
    },
  };

  const nextSlideHandler = () => {
    if (owlRef && owlRef.current) owlRef.current.next(1000);
  }
  const previousSlideHandler = () => {
    if (owlRef && owlRef.current) owlRef.current.prev(1000);
  }
  return (
    <div>
      <div className='container service-container my-5'>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className='portrait-header'>
                  <p className='process-main-header-text m-0'> {feedback.headerText}</p>
                  <h1 className='process-main-title m-0'> {feedback.title}</h1>
                  <h2 className='process-main-subText m-0'>{feedback.subTitle}</h2>
                  <p className='process-description process-spacing' >{feedback.description.description}</p>
                </div>
              </div>
            </div>
            <OwlCarousel
              className='owl-carousel testimonials-carousel'
              {...options}
              ref={owlRef}
            >
              {feedback.multiFeddback.map((p) => {
                return (
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center flex-column justify-content-center">
                      <div className='portrait-card-container d-flex align-items-center'>
                        <div className='pr-4 pr-sm-0 pr-md-4 mb-sm-2'>
                          <GatsbyImage image={p.profile.gatsbyImageData} alt="image" className='testimonials-image' />
                        </div>
                        <div>
                          <p className='mb-2 t-card-description'>{p.description.description}</p>
                          <p className='m-0 t-card-description-bold'>{p.userName} <span className='t-card-descriptions'>{`-${p.profession}`}</span></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-center">
                      <ReactPlayer width={isTablet ? 300 : isMobileSmall ? 200 : isMobile ? '100%' : 424} height={isTablet ? 400 : isMobileSmall ? 300 : isMobile ? 500 : 754}
                        url='https://www.youtube.com/shorts/28sww2sbI4U?&ab_channel=JagritiVishaliShorts' />
                    </div>
                  </div>

                )
              })}
            </OwlCarousel>
            <div className='portrait-btn d-flex'>
              <div className="testimonial-square d-flex justify-content-center align-items-center" onClick={previousSlideHandler}>
                <img src={arrowLeft} alt="arrow" className='m-0 testimonial-img' />
              </div>
              <div className="testimonial-square d-flex justify-content-center align-items-center ml-2" onClick={nextSlideHandler}>
                <img src={arrowRight} alt="arrow" className='m-0 testimonial-img' />
              </div>
            </div>

            {/* <div className="row">
              <div className="col-6">

              </div>
              <div className="col-6">
                test
              </div>
            </div> */}

          </div>
          {/* <div className="col-12 col-lg-6">
            <img src={img} alt="" />
          </div> */}
        </div>
      </div>
    </div>
  )
}
