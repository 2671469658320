import React from 'react'
import MainTitle from '../MainTitle/MainTitle'
import './OurService.css'
import Icon1 from '../../../images/Group 34.png'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

export default function OurService({ service }) {
  return (
    <section id="service" className='pt-3'>
      <div className="container service-container my-md-5">
        <MainTitle header={service.header} title={service.title} subTitle={service.subTitle} />
        <div className="row our-service-card-container">
          {service.ServiceType.map((p) => {
            return (
              <div className='col-12 col-md-6 col-lg-4'>
                <div className="our-service-card">
                  <div className='row m-0 our-service-card-text-header'>
                    <div className="service-icon-square d-flex justify-content-center align-items-center" style={{ background: `${p.backgroundColor}19` }}>
                      {/* <img src={p.serviceImage.fluid.src} alt="icon" className='m-0 our-service-icon' /> */}
                      <GatsbyImage image={p.serviceImage.gatsbyImageData} alt="image" />
                    </div>
                    <div className='service-header-container'>
                      <h1 className='our-service-card-header mb-2 pl-3'>{p.title}</h1>
                      <h2 className='our-service-card-subHeader pl-3 m-0'>{p.subTitle}</h2>
                    </div>
                  </div>
                  <p className='our-service-card-description'>{p.description.description}</p>
                  {/*<Link to={p.slug} className='m-0 service-more-button'>Know More</Link>*/}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
