import React from 'react'
import './MainTitle.css'

export default function MainTitle({ header, title, subTitle, ...props }) {
  return (
    <>
      <p className='main-header-text m-0'> {header}</p>
      <h1 className='main-title m-0'>{title}</h1>
      <h2 className='main-subText m-0'>{subTitle}</h2>
    </>
  )
}
