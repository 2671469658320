import React from 'react'
import MainTitle from '../MainTitle/MainTitle'
import './OurProcess.css'
import Process from '../../../images/FlowImage.svg'

export default function OurProcess({ process, ...props }) {
  return (
    <section id="about" className='pt-4'>
      <div className='container service-container my-5'>
        <div className="row">
          <div className="col-12 col-lg-5">
            {/* <MainTitle header="Our Process" title="Process" subTitle="Introduce Our Process" /> */}
            <p className='process-main-header-text m-0'>{process.header}</p>
            <h1 className='process-main-title m-0'>{process.title}</h1>
            <h2 className='process-main-subText m-0'>{process.subTitle}</h2>
            <p className='process-description mt-5'>{process.description.description}</p>
          </div>
          <div className="col-12 col-lg-7">
            <img src={Process} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
