import React, { useState } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import './ContactUs.css'
import img from '../../../images/Group 853.png'

export default function ContactUs({ contactUs }) {
  const [state, setState] = useState({
    name: "",
    email: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);


  const handleChange = (e) => {
    const value = e.target.value;
    setState({ ...state, [e.target.name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("http://localhost:8000/api/tasks/",
      {
        method: "POST",
        headers: {
          "content_type": "application/json"
        },
        body: JSON.stringify(state)
      })
      .then(res => {
        setLoading(false);
        setState({ name: '', email: '', description: '' })
      }).catch(er => {
        setLoading(false);
      })
  }
  return (
    <section id="contact" className='pt-3'>
      <div className="container service-container my-5">
        <MainTitle header={contactUs.headerText} title={contactUs.title} subTitle={contactUs.subTitle} />
        <div className="row">
          <div className="col-12 col-lg-6">
            <img src={img} alt="" />
          </div>
          <div className="col-12 col-lg-6">
            <h1 className='contact-header mb-2'>
              {contactUs.subHeader.split(',').reduce((all, cur) => [
                ...all,
                <br />,
                <h2 className='contact-sub-header pt-2'>{cur}</h2>
              ])}
            </h1>
            <p className='contact-description m-0'>{contactUs.description.description}</p>
            <form onSubmit={handleSubmit}>
              <div class="mail_section">
                <div className="row">
                  <div className="col-6">
                    <input required type="text" class="mail_text" value={state.name} name="name" onChange={handleChange} placeholder="Name" />
                  </div>
                  <div className="col-6">
                    <input required type="email" class="mail_text" value={state.email} name="email" onChange={handleChange} placeholder="Email" />
                  </div>
                  <div className="col-12">
                    <textarea required class="mail_text" value={state.description} name="description" onChange={handleChange} placeholder="Description" id="exampleFormControlTextarea1" rows="1" />
                  </div>
                </div>
                <div className='mt-5'>
                  <button disabled={loading} className='contact-btn' type="submit" value="Submit">{
                    loading ? "Loading..." : "Get Free Quote"}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
