import React, { useEffect, useRef, useState } from "react"
import SEO from "../components/seo"
import Navbar from "../components/Dashboard/Navbar/Navbar"
import './style.css';
import LessIcon from '../images/expand_less_black_24dp.svg'
import Header from "../components/Dashboard/Header/Header";
import OurService from "../components/Dashboard/OurService/OurService";
import OurProcess from "../components/Dashboard/OurProcess/OurProcess";
import OurProject from "../components/Dashboard/OurProject/OurProject";
import { Helmet } from "react-helmet"
import Testimonial from "../components/Dashboard/Testimonial/Testimonial";
import ContactUs from "../components/Dashboard/ContactUs/ContactUs";
import Footer from "../components/Dashboard/Footer/Footer";
import { useScrollRestoration, graphql } from "gatsby";

export const query = graphql`
    query MyQuery {
   homePage: contentfulHomePage(slug: {eq: "/"}) {
    title
    themeColor
    navbars {
      title
      to
      hRef
    }
    header {
      title
      description {
        description
      }
      actionImage {
              gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE ,  width:50 )
      }
      image {
              gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE  )
      }
       mockup {
        name
        description {
          description
        }
        profile {
          gatsbyImageData(layout: CONSTRAINED)
        }
        userName
        profession
        desktopView {
          gatsbyImageData(layout: CONSTRAINED)
        }
        mobileView {
          gatsbyImageData(layout: CONSTRAINED)
        }
        tabletView {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
   Service {
      title
      header
      subTitle
      ServiceType {
        title
        subTitle
        serviceImage {
          gatsbyImageData(layout: CONSTRAINED)
        }
        description {
          description
        }
        backgroundColor
        slug
      }
    }
    process {
       header
      title
      subTitle
      description {
        description
      }
    }
    quote {
      title
      description {
        description
      }
      heroImage {
         gatsbyImageData(layout: CONSTRAINED)
      }
      buttonText
    }
   recentWork {
      title
      subTitle
      headerText
      multiRecentWork {
        headerText
        title
        subTitle
        heroImage {
           gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    feedback {
      title
      subTitle
      headerText
      description {
        description
      }
      multiFeddback {
        profile {
          gatsbyImageData(layout: CONSTRAINED)
        }
        description {
          description
        }
        userName
        profession
      }
    }
    contactUs {
      headerText
      title
      subTitle
      subHeader
      description {
        description
      }
    }
     footer {
      logo {
         gatsbyImageData(layout: CONSTRAINED)
      }
      email
      phoneNumber
      footerHeading {
        title
        footerSection {
          slug
          text
        }
      }
      socialLink {
        icon {
           gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    footerWaveColor {
      firstWaveColor
      secondWaveColor
    }
    copyright {
      title
    }
  }
    }
    `

const IndexPage = ({ location, data: { homePage } }) => {

  const [showScrollToTop, setShowScrollToTop] = useState(0);
  const scrollRestoration = useScrollRestoration("dashboard");

  const handleScroll = () => {
    const position = window.pageYOffset;
    setShowScrollToTop(position > 1000);
    // if (position > 1000 && !showScrollToTop)
    //   setShowScrollToTop(true);
    // else if (position < 1000 && showScrollToTop)
    //   setShowScrollToTop(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showScrollToTop]);

  function scrollHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  return (
    <div {...scrollRestoration}>
      <Helmet>
        <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
        <script src="lib/owlcarousel/owl.carousel.min.js"></script>
      </Helmet>
      <SEO title={homePage.title} />
      <Navbar navbar={homePage.navbars} themeColor={homePage.themeColor} />
      <Header header={homePage.header.reduce((p, c) => ({ ...p, ...c }), {})} />
      <OurService service={homePage.Service} />
      <OurProcess process={homePage.process} />
      <OurProject project={homePage.recentWork} />
      <Testimonial feedback={homePage.feedback} />
      <ContactUs contactUs={homePage.contactUs} />
      <Footer footer={homePage.footer} copyright={homePage.copyright} />
      <div className="scroll-button-circle" onClick={scrollHandler} style={{ display: showScrollToTop ? 'flex' : 'none', background: "#F8602D" }}>
        <img src={LessIcon} alt="lessIcon" className="mb-0 less-icon" />
      </div>
    </div>
  )
}

export default IndexPage
