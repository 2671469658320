import React, { useRef } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import './OurProject.css'
import Loadable from "@loadable/component"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img from '../../../images/christopher-gower-m_HRfLhgABo-unsplash.jpg'
import arrowLeft from '../../../images/left.png'
import arrowRight from '../../../images/right.png'
import { GatsbyImage } from "gatsby-plugin-image"
const OwlCarousel = Loadable(() => import("react-owl-carousel"))

export default function OurProject({ project }) {
  const owlRef = useRef(null);

  const options = {
    responsiveClass: true,
    autoplay: false,
    smartSpeed: 1000,
    items: 6,
    dots: false,
    center: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,

      }
    },
  };


  const nextSlideHandler = () => {
    if (owlRef && owlRef.current) owlRef.current.next(1000);
  }
  const previousSlideHandler = () => {
    if (owlRef && owlRef.current) owlRef.current.prev(1000);
  }

  const settings = {
    lazyload: true,
    nav: false,
    mouseDrag: true,
    items: 3
  };

  return (
    <section id="work" className='pt-3'>
      <div className="container service-container my-5">
        <MainTitle header={project.headerText} title={project.title} subTitle={project.subTitle} />
        <div className="testimonial wow fadeInUp" data-wow-delay="0.1s">
          <OwlCarousel
            className='owl-carousel testimonials-carousel'
            {...options}
            ref={owlRef}
          >
            {project.multiRecentWork.concat(project.multiRecentWork).concat(project.multiRecentWork).map((p, i) => {
              console.log(p, "clas")
              return (
                <div key={`${p.title}-${i}`} className={`testimonial-item ${i}`}>
                  <div className="testimonial-text">
                    <img src={p.heroImage.gatsbyImageData.images.fallback.src} alt="" />
                    {/* <GatsbyImage image={p.heroImage.gatsbyImageData} alt="image" /> */}
                  </div>
                  <div className='testimonial-action-btn'>
                    <img src={arrowRight} alt="arrow" className='action-btn-arrow' />
                  </div>
                  <div className='active-slide'>
                    <h1 className='active-card-text'>{p.headerText}</h1>
                    <div>
                      <h2 className='active-card-header m-0'>{p.title}</h2>
                      <h3 className='active-card-subHeader m-0'>{p.subTitle}</h3>
                    </div>
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
        </div>
        <div className='testimonial-btn d-flex'>
          <div className="testimonial-square d-flex justify-content-center align-items-center" onClick={previousSlideHandler}>
            <img src={arrowLeft} alt="arrow" className='m-0 testimonial-img' />
          </div>
          <div className="testimonial-square d-flex justify-content-center align-items-center ml-2" onClick={nextSlideHandler}>
            <img src={arrowRight} alt="arrow" className='m-0 testimonial-img' />
          </div>
        </div>
      </div>
    </section>
  )
}